.custom-toast-container {
  width: 100%;
  height:auto;
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-inline: 1rem;
  padding-top: .7rem;
  padding-bottom: .7rem;
  gap: 1.5rem;
  cursor: auto;
}

.react-toast {
  width:25rem;
  margin:0;
  padding: 0;
  background: transparent !important;
  box-shadow: none !important;
  cursor: auto !important;
}

.success-toast,.info-toast{
  background: #f6fef9;

  border: 1px solid #d1fadf;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
}

.info-toast{
  background: #F5F8FF;
  border: 1px solid #A3C0FF;
}


.noti-msg{
    margin-right: 1rem;
    flex:1;
    
}

.error-toast{
  background: #FFFBFA;
  font-family: inter;
  color: #B42318;

  border: 1px solid #FEE4E2;;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
}