
.userprofile-container {
   
    width: 100%;
    height: 100%;
    padding:1.5rem 2rem 0 2rem;
    background-color: #ffffff;
    font-family: 'inter';
  
   
    --divider-color:#EAECF0;
    --width:95.6%;
   
    position:relative;
    overflow-y: scroll;

   
}
.userprofile-container::-webkit-scrollbar{
    display:none
}

.profile-header-container{
    
    position: absolute;
    width:var(--width);
    height:10.5%;
    border-bottom:1px solid var(--divider-color);
    
    
}


.profile-heading:first-child {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
   
    color: #101828;
   

}

.profile-heading span:nth-child(2) {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: .9rem;
    line-height: 1.5rem;
    color: #475467;
    
    
}




.forms-container {
    position:absolute;
    display: flex;
    
    flex-direction: column;
    justify-content: space-between; 
    width:var(--width);
    height: 80%;
    bottom:3%;
   
 
}
.form-container1,.form-container2{
   
    width:100%;
    
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  
   
 
}
.form-container2{
     margin-top: 1rem;
    
}

.form-title{
    
    border-bottom:1px solid var(--divider-color);
    margin-bottom: 1vh;

   
}

.form-title :first-child {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom:.1vh ;
    color: #101828;
  
}

.form-title span:nth-child(2) {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: .9rem;
    line-height: 1.5rem;
    color: #475467;
    margin-bottom:1rem;
  
  
}
.user-profile-form{
   
}

.form-field{
   
    height:fit-content;
    padding:1.3vh 0 1.5vh 0;
    display: flex;
    align-items: center;
 
   
}






.form-label,.input-field{
    font-family: 'inter';
    width:25%;
    height:4.6vh;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    font-size:1rem;
    font-weight: 500;
    padding:5px;
   
}

.form-label{
    
    margin:0 !important;

}
.input-field{
    border:1px solid #D0D5DD;
    font-size: .9rem;
    outline: none;
    border-radius:8px;
    padding-inline: 10px;

    
}

.e-mail-formfield,.confirm_new_password-formfield{
    border-top:1px solid var(--divider-color);
    border-bottom:1px solid var(--divider-color);
}

.submit-btn{
    width: fit-content;
    height:2.2rem;
    border:1px solid #0042D2;
    border-radius:8px;

    background:#0042D2;


    font-family: 'inter';
    font-weight:500;
    font-size:14px;
    line-height: 20px;
    color:#FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    margin:1rem 0 .5rem 0;
    padding-inline: 10px;

}



