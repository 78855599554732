.allowedTable-container{
   
  
    height:75vh;
    padding-right:2rem;
    padding-left:2rem;
   
    
    
}

.migration {
    display: flex;
    justify-content: center;
    align-items:center;
    margin-top: 5.5rem !important;
   
    position:relative;
  }
  .migration-btn{
  
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:absolute
  }

  .right_left_button{
    background-color:#FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
     
    color: #344054;;
    width:10rem;
    height:2.2rem;
   
}