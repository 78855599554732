:root {
  --width: 95.5%;
}



.title-container {
  width: var(--width);
  height: fit-content;
  margin: 1rem auto 0 auto;
}

.current-app-table {
  display: flex;
  align-items: center;
  gap:1em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;

  color: #101828;
}

.actions-container-backdrop {
  width: var(--width);
  position: relative;
  height: 100%;
  min-height: 5rem;
  max-height: 7rem;

  margin: auto;

  background: #f9fafb;

  border-radius: 12px;

  display: flex;
  align-items: center;

  
 
}

.actions-container {
  position: absolute;
  display: flex;
  align-items:end;
  gap: 0.8rem;
  width: 100%;
  bottom:19%;

  padding-left: 2%;
  padding-right: 2rem;
  

  height: 85%;
 

 
}


.line-seperator-container{
  height:95%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  
 

}
.line-seperator {
  width: 1px;
  border: 1px solid #d0d5dd;
  height: 60%;
}
.searchfield-container,.pagesize-container {
  width: 50%;
  height: 100%; 

  display: flex;
  flex-direction: column;
  justify-content:flex-end;

}

.pagesize-container {
  width: 6rem;
}



.button-label {
  font-family: "inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom:.2rem;
  color: #344054;
}

.pageSize-input {
  height: 50%;
  max-height: 3rem;
  width:100%;

  background: #ffffff;
  border: 1px solid #d0d5dd;
  font-family: "inter";
  font-size: 1rem;
  font-weight: 400;
  border-radius: 8px;
  opacity: 1;
  padding-inline: 10px;
  margin: 0 ;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.button-container{
   
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;

}



.table-container {
  width: var(--width);
  height: 80%;
  margin: 0.8rem auto;
  margin-bottom: 1.7rem;
}


/* dag status */

.dag-status-container{
  
  display: flex;
  align-items: center;
  gap:.75em;
  width:fit-content;
  height:2.2em;
  
  padding:.15em .5em .15em;
  border-radius:1em;
  font-size: 1rem;
}
.status-active{
  background:#ECFDF3;
  color:#027A48;

}
.status-failed{
  background: #FDA29B;
  color: #B42318;

}

.status-invalid{
  
  background:  #FEDF89;
  color: #333;
}
.dag-status{
  padding-inline:.5em;
  background:#FFF;
  border-radius:1em;


}