/* *{
    border:1px solid grey;
} */

.sidebar-icons-container {
    width: 100%;
    height: 100%;
    background: #101828;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}



.upper-icons-container {
    position: absolute;
    height: 50%;
    width:52%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-top: 2rem;
   

}

.lower-icons-container {
    position: absolute;
    width:52%;
    height: 50%;
    gap: .8rem;
    bottom:2.5%;
   
  
    

}

.settings-icon-container{
   
    position: absolute;
    bottom:0%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    

}

.nav-logo {
    margin-bottom: 1rem;
}

.nav-icons {

    width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 5px;

    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-icons:hover,
.nav-icons:focus {
    background: #344054;

}

.avatar-icon {
   

    font-family: 'inter';
   
 
    width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    min-width: 2.5rem;
    
    background: #F5F5F5;
    border-radius: 50%;
    border: none;

}

/* sidebar css (opened) */
.sidebar-container {

    position: relative;
    width: 100%;
    height: 100%;
    background: #1D2939;
    padding-top: 1.1rem;
    



}


.apptype-title-container {
    height:7%;
    display: flex;
    align-items:center;
 


}

.apptype-title {

    display: flex;
    align-items:flex-end;
    padding-left: 1rem;
    font-family: 'inter';
    font-size: 1rem;
    font-weight: 550;
    color:#ffffff;

}

.applist-dropdown-container {
    position: absolute;
    top:6%;
    margin-top:1rem;
    height: 67%;
    width:100%;
   
   
}

.userInfo-Container {
    position: absolute;
    bottom:2.5%;
  
    width:100%;
  
    height: 20%;
   
    display: flex;
    justify-content:space-between;
    align-items:flex-end;
   
    overflow: hidden;
   
}



.userInfo {
    font-family: 'inter';
    padding:8px;
    padding-left: 20px;
    color: #ffffff;
    line-height: 5px;
    font-size: .7rem;
    font-weight: 500;
 

}
.userInfo p{
   
    font-family: 'inter';
    font-size: .9rem;
}

.userInfo>span {
    font-size: .8rem;
    font-family: 'inter';
    font-weight: 300;
    color: #F2F4F7;

}
#logout-btn{
    
    padding-bottom:2px ;
    cursor: pointer;
}


.dropdown-container {

    height: fit-content;
    width:95%;
    max-height: 20rem;
    margin: 0 .6rem;
  


}

.app-name {

    height: 2.5rem;
    margin: 0 .5rem 0 .5rem;
 

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 15px;

    font-family: 'inter';
    font-size: 1rem;
    font-weight: 500;
    color:#FFFFFF;
    cursor: pointer;
   

}

.app-name:hover {

    background: #344054;
    border-radius: 6px;
}

.dropdown-content {


    margin:3px .6rem;
    text-align: left;

    font-family:'inter';
    height: fit-content;
    max-height: 13rem;
    overflow-y: scroll;
    cursor: pointer;
}

.dropdown-content::-webkit-scrollbar {
    display: none;
}

.dropdown-container p {

    height: 2.5rem;
    display: flex;
    align-items: center;
    padding:0;
    padding-left: 2.3rem;
    margin:0;
    font-family: 'inter';
    font-weight: 500;
    font-size: 1rem;
    color: #F2F4F7;
}

.dropdown-container p:hover {
    background: #101828;
    border-radius: 6px;


}