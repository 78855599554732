.dropdown-container{
    position:relative;
   
    
}
.dropdown-header{ 
    display:flex !important;
    align-items: center;
    justify-content: space-between;
    width:110px;
    height:30px;
    background-color: #FFFFFF;
    border: 1px solid #B6B6B7;
    border-radius: 5px;
    opacity: 1;
   
    
}

.dropdown-title  {
    font: normal normal bold 15px/18px Lato;
    letter-spacing: 0px;
    color: #B6B6B7;
    opacity: 1;
}

.option{
    font: normal normal bold 14px/17px Lato;
    letter-spacing: 0px;
    color: #B6B6B7;
    opacity: 1;
}

.option:hover{
    color:#333;
    background-color:#B6B6B7
}
    
.droodown-item{
    font: normal normal bold 14px/17px Lato;
}


.dropdown-list{
    display:block;
    position:absolute;
    top: 30px;
    width:110px;
    z-index: 2;
    background-color: #FFFFFF;
    border: 1px solid #B6B6B7;
    border-radius: 5px;
    

    
}
.arrowIcon{
    color:#2D81A1;
    font-size: 20px;
}


.modalnav-container{
   width:90%; 
   
}

.modalnav-button{
    position: absolute;
    right:0%;
    bottom: 0%;
}

