.airflowConfig-container {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    margin-left: 7%;
    margin-right: auto;
    width: 90%;
    height: 80%;

}

.tableColumnCreator {
    margin-top: 1.5rem;

    padding: 5px;
    background: #fff;
    padding: .5rem;
    border-radius: 5px;
    margin: .5rem;
    
}



.formfields {
    font-size: 1.1rem;
    color:#333;
}

.rowOptionsBox {
    background: #fff;
    padding: .5rem;
    border-radius: 5px;
    margin: .5rem !important;

}

.dag-credantials {
    background: #fff;
    padding:.3rem .3rem .7rem .3rem !important;
  
    border-radius: 5px;
    margin: .5rem !important
}

.airflow-config-btn{
    background:#fff 0% 0% no-repeat padding-box;
    color:#616161;
    border:1px solid #ced4da;;
    font-size:1rem;
    align-items: center;
    justify-content:center;
}
.airflow-config-btn-disabled{
    background:#fff 0% 0% no-repeat padding-box;
    color:	#BEBEBE;
    border:1px solid #ced4da;;
    font-size:1rem;
    align-items: center;
    justify-content:center;
}

.submitDiv{
   
    margin-inline: .5rem !important;
    margin-top: .1rem !important;
    padding:0 !important;
  
}

.form-check-label{
    color:#616161 !important; 
}
.form-label{
    color:#616161 !important; 
}


.table-buttons{
   
    bottom:0;
    top:auto;
}
.table-columns{
    height:8.5rem;
    max-height:25rem;
    overflow-y: scroll;
    overflow-x: hidden;

}