
.breadcrumbs-container {
    
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: .6rem;
    margin-top: -1rem;
    width: -moz-fit-content;
    height:2rem;
    
    border-radius: 6px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .8rem;
   
    
}

.breadcrumb-container {
    
    padding-left:2.2rem;
    padding-right: 0.5rem;
    margin-top: 1.5rem;
    
    height:2rem;
    
    border-radius: 6px;
    width:100%;
    display: flex;
    align-items:flex-start;
   
   
    
}
.breadcrumb{
    color: #475467;
    font-size: .9rem;
    font-weight: 500;
    font-family: 'inter';
    height: 100%;
    width:fit-content;
    padding:10px;
    margin-right: .5rem;
   
    border-radius: 6px;
   
    display: flex;
    justify-content:center;
    align-items: center;
    
    text-transform: capitalize;
    
   
}
.breadcrumbs  {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;


    
}
.breadcrumbs a{
    color: #475467;
    text-decoration: none;

}

.breadcrumb-active{
   
    font-weight: 600;
    font-family: 'inter';
    color: #344054;
    background: #F9FAFB;


}
.bc-clickable{
    cursor:pointer
}