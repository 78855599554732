.ag-theme-alpine {
  font-family: "inter", sans-serif !important;

}


.cell-dropdown-renderer{
    width: 85%;
    height:30px;
    position: absolute;
    display: flex;
    justify-content:space-between;
    align-items: center;
  
    padding-inline:13px;
    margin-top: 7px;

    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
   
}

.select-dropdown-container{
    position:absolute;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    padding:0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width:68.3%;
    height:2rem;
    margin:.1rem auto;
    margin-left:1.3rem;
    padding-inline:20px;
  

    display: flex;
    align-items:center;
    justify-content: space-between;
   
}



/* 
ag grid css */

.header-class {
    font-style: normal;
    font-family: 'inter';
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #475467;
  
  }
  
.cell-class {
  
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  
  }
  
  
.ag-row {
    z-index: 0;
  }
  
  .ag-row.ag-row-focus {
    z-index: 1;
  }
  
  .ag-theme-alpine input[class^=ag-] {
    color: #515153 !important
  }
  
  /* ag grid table border */
  .ag-root-wrapper {
    border: 1px solid #ffffff;
    border-bottom: 1px solid #EAECF0;
    border-radius: 8px;
  
  
  
  }
  
  .ag-header-viewport {
    background: #F9FAFB;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
  
  
  }
  
  
  .ag-force-vertical-scroll {
    border-right: 1px solid #C0C0C0;
  }
  
  .ag-icon-filter,
  .ag-icon-menu {
    color: #fff
  }
  
  .ag-row-selected {
    border: 1px solid #A3C0FF !important;
    background: #F5F8FF !important;
  
  }
  
  /* .ag-row-selected>.cell-class {
    background-color: #ffffff;
    border: none !important
  } */
  
  .ag-picker-field-display {
  
    color: #333
  }
  
  
  div[id^='react-select'][id$='listbox'] {
    color: #333 !important;
    overflow: hidden
  }
  
  
  div[class$='ValueContainer'] {
    padding: 0 !important
  }
  
  .ag-text-field-input-wrapper{
    padding-inline:8px !important;
    outline: none!important;
    
  
  }
  
  /* cell editing style */
  
  .ag-text-field-input-wrapper {
    padding-inline:20px !important;
    outline: none!important;
}
  
 
.ag-text-field-input {
    outline:none !important;
    box-shadow: none !important;
    width:5rem !important;
    height: 30px !important;
    padding:3px !important;
    margin-right: 1rem;
  

    background: #FFFFFF !important;
  

    border: 1px solid #5288FF !important;
  

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCDCFF !important;
    border-radius: 5px !important;
    
  }
   
  
  
  .ag-cell-focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }


  /* testing */


  .ag-input-wrapper, .ag-picker-field-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    -webkit-box-align: center;
            align-items: center;
    line-height: normal;
    position: relative;
   
    background:#F5F8FF;
   
  }

  .ag-text-field-input-wrapper{
    padding-inline: 5px !important;
    

  }


  /* dropdown  */

  .ag-cell-value,.ag-group-value{
    display: flex;
    align-items: center;
    overflow:auto;
   
  }
 
  .ag-cell-inline-editing {
    background-color:  #F5F8FF !important;
  }



  /* date picker */

 .datePicker-wrapper{
 
  background-color: #F5F8FF !important;
  border:none !important;
  display: flex !important;
  align-items: center !important;
  padding-left: 1rem !important;
  padding-top: .5rem !important;



  }

  .datePicker{
    border: 1px solid #5288FF ;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #CCDCFF !important;
    background: #FFFFFF !important;
    height:30px;
    border-radius:8px;
    width:90%;
    display: flex ;
    justify-content:space-between;
    align-items: center;
    
  }