.grid-container{

    display:grid;
    grid-template-columns:80px auto 1fr;
    grid-template-rows: repeat(15,1fr);
    width:100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 100;
    transition: grid-template-columns 0.5s ease;
    transition: grid-template-rows 0.5s ease;
    
    
}

.sidebar-icons{

    grid-row:1/16;
    position:relative
    
}
.sidebar-layout{
    
    position:relative;
    grid-row:1/16;
    width:280px;
    
    transition: width 0.3s ease-out;
   

}
.sidebar-hidden{
    grid-row:1/16;
    width:0;

    transition:width 0.5s ease;
    
}

.title-layout{
    grid-row:2/3;
   
    
}
.actions-layout{
    grid-row:3/5;
    position:relative;
 
   
   
}
.table-layout{
    position: relative;   
    grid-row:5/16;  
}

.table-layout-jobrunner{
    position: relative;   
    grid-row:4/16;  
}

.pagination-layout{
    width:100%;

   
}

.dashboard-layout,.user-profile-layout{
    position:relative;
    grid-row: 1/16;
   

}

.user-profile-layout{
    padding:2rem
}