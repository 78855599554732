.dashboard-container {

    width: 100%;
    height: 100%;
    padding:1.5rem 0 0 3rem;
    padding-left:2rem;
    background-color: #ffffff;
    font-family: 'inter';
  

}

.bc-bg{
    background: #F9FAFB;
   
}

.welcome:first-child {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: #101828;
    margin:0;
   
}

.welcome span:nth-child(2) {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #475467;
    margin:.3rem 0 .5rem 0;
   
   
}


.card-container {
    height: 70%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  
    padding-right:2rem ;
    margin-top: -1rem;
   
  
   
   
   
}

.card-container::-webkit-scrollbar {
    display: none;
}

.Card,.settings-card{
    box-sizing: border-box;
    border: 1px solid #D0D5DD;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    height: fit-content;
    margin-top: 1.6rem;
   
    padding: 1rem;
   
    font-family: 'inter';
    font-size: .8rem;
    



}

.description {
    display: block;
    font-family: 'inter';
    font-weight: 400;
    font-size:.8rem;
    line-height: 20px;
    color: #475467;
    margin: .1rem 0 .4rem 0;



}




.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: .6rem;
    width: 2.2rem;
    height: 2.2rem;
    min-height: 2.2rem;
    min-width: 2.2rem;

    background: #F5F5F5;
    border-radius: 100%;


}



.title {
    font-style: normal;
    font-weight: 600;
    font-size: .9rem;
    color: #344054;
    padding: 0;
    
}

.link-label {
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
    line-height: 20px;
    color: #0035A6;
  
}


.dashboard-divider {
    border-bottom: 1px solid #EAECF0;
    width: 97.8%;
    margin: .5rem  0 1rem  0;
  
}



@media screen and (max-height:550px)  {
    .Card {
        padding:.3rem .7rem;
       
    }

    .description{
        margin:0;
       
    }


}

@media screen and (max-width:880px) {
    /* .link2{
        display:block
    } */
    .settings-card{
        padding:.5rem .1rem .7rem .4rem ;
    }
}

@media screen and (max-width:820px) {
    .link2{
        display:block
    }
   
}
