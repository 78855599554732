@-moz-document url-prefix() {
  html {
    font-size: 16px;
  }
}

html {
  font-size: 16px;
}

/* font */
@font-face {
  font-family: "inter";
  src: url("./newUiStyles/fonts/Inter-Regular.ttf");
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "inter";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-inter{
  font-family: "inter";
  font-size: 1rem;
}
#root {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  --hover_background: #2d81a1;
  --hover-color: #fff;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100vw;
  height: 100vh;
}

.sidebar {
  height: 100%;
  width: 250px;
  top: 0;
  left: 0;
  background-color: #151c2e;
  float: left;
  position: fixed;
  overflow: hidden;
}

/* currentAppTable */

.currentAppTable,
.currentAppTable-moved {
  color: #515153;
  text-align: flex-start;
  width: 75%;
  padding-left: 0.5rem;
  font-size: 1.2rem;
  font: bold 20px Lato;

  transition: 100ms;
}

.currentAppTable-moved {
  margin-left: 12.7rem;
}

.custom-select {
  display: flex;
  align-items: center;
  background-color: #515153;
  position: relative;
  color: #ffffff;
  padding: 1.1em;
  margin: 0.15em;
  width: 250px;
  height: 40px;
}

.role-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #515153;
  font: normal normal bold 18px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-inline-start: 0.8em;
  width: 100%;
}

.role-list {
  height: auto;
  width: auto;
  margin-left: 3.5em;
}

.role-option {
  font: normal normal bold 18px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 5px;
  height: auto;
}

.role-option:hover {
  background: var(--hover_background);
  color: var(--hover-color);
  cursor: pointer;
}

select {
  border: 0px;
  outline: 0px;
}

.custom-select:hover {
  background: var(--hover_background);
  color: var(--hover-color);

  cursor: pointer;
}

.custom-select:hover .role-select {
  background: var(--hover_background);
  color: var(--hover-color);
}

.apps {
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  overflow-y: hidden;
}

.app-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 20px;
  font-size: 18px;
}

.app-button:hover {
  background: var(--hover_background);
  color: var(--hover-color);

  cursor: pointer;
}

/* db credentials css */

.Container {
  margin-left: 5.8rem !important;
  width: 100% !important;
  position: absolute !important;
  /* border:1px solid red; */
  margin-left: 0.1rem;
}

.sub-menu {
  list-style: none;

  margin-left: -1em;
  display: none;
}

.sub-menu li {
  background-color: #28282b;
  margin: 4px 0px 4px;
}

#sidebarlink-span {
  position: absolute;
  margin-left: calc(100% - 60px);
}

.settings-item {
  display: flex;
  align-items: center;
  width: 12.1rem;
  margin-left: 1.5rem;
  padding: 10px 5px 10px 18px !important;
  height: 27px;
  font-size: 0.9rem;
  transition: 100ms transform;
}

.settings-item:hover {
  background: var(--hover_background);
  color: var(--hover-color);
  cursor: pointer;
}

.linkactive {
  text-decoration: none;
  color: crimson;
}

.link {
  text-decoration: none;
  color: #fff;
}

.main {
  width: 100%;
  height: 100vh;

  overflow: hidden;
  transition: 200ms;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  overflow: hidden;
}

.main-sifted {
  position: relative;
  margin-left: 165px;
  width: auto;
  height: 100vh;
  overflow: hidden;
  transition: 200ms;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

.view-container {
  position: absolute;
  width: 90%;
  height: 100%;
}

.error {
  margin-inline-start: 2px;
  font-size: 0.9rem;
  color: #F04438;
}

/* dialog styling */

.overlay {
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  position: absolute;
  background: rgba(52, 64, 84, 0.7);
}

.modelForm {
  background: #ffffff;
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
  height: auto;
  width: 40rem;
  max-height: 37rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-header {
  display: flex;
  align-items: center;
  height: 4rem;
  width: 100%;
 
}

.dialog-title {
  width: 70%;
  color: #101828;
  font-family: "inter";
  font-size: 1.1rem;
  font-weight: 600;

  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: -0.25rem;
  padding-top:.8rem;
}

.dialog-close {
  display: inline-block;
  width: 20%;

  font-size: 1.3rem;
  font-weight: 900;
  transform: translateX(90%);
}



.dialog-form-fields {
  height: fit-content;

  width: 100%;
  max-height: 25rem;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  padding: 0.5rem;
  
  background: transparent;
 
  /* border:1px solid red; */
}

.dialog-form-fields::-webkit-scrollbar {
  width:.8rem;
  /* Safari and Chrome */
}

.dialog-form-fields::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
  border-radius: 100vw;
  
}

.dialog-form-fields::-webkit-scrollbar-thumb{
  background:#707070;
  height:3rem !important;
  border:.1em solid #D0D5DD;
  border-radius: 100vw;
}
.dialog-form-fields::-webkit-scrollbar-thumb:hover{
  background:hsl(220, 43%, 11%,0.8);

}

.form-fields {
  margin: auto;
  margin-bottom: 1rem;
  height: fit-content;
  padding: 0.5rem auto;
  width: 92%;
 
  position:relative
}

.input-label {
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;

  font-family: "inter";
  font-size: 1rem;
  font-weight: 500;
  color: #344054;
  text-transform: capitalize;
}
.dialog-input-container{

  position:relative;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width:100%;
  
}

.form-control {
  height: 2.5rem;
  width:100%;
  position:absolute !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  font-family: 'inter'!important;
  padding-right: 35px !important;
  
}

.form-control:focus {
  border: 1px solid #5288ff !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ccdcff !important;
}

.input-error-icon{
 
  position: absolute;
  top:50%;
  right:1%;
  transform: translate(-50%, -50%);

  padding:1px;
}

.dialog-submit {
  background: #ffffff;
  display: flex;
  gap:.5rem;
  max-height: fit-content;
  justify-content:space-between;
  align-items: center;

  width:88%;
  height: 4rem;
  
  
  margin:.5rem .1rem .2rem 2rem;
  position:relative;
  


}

.btnDiv {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.2rem;
  padding: 10px 0px 0 0;
}

.searchDiv {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.searchInput {
  background-color: #ffffff;
  font-family: inter;
  width: 100%;
  color: #515153;
  border: none;
}

.btn {
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
}

.btn-success {
  background-color: #2d81a1 !important;
}


/* toast css */

.toastContainer {
  width: auto !important;
  padding-inline: 20px !important;
  height: auto;
}

.info-alert {
  width: auto;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 20px;
  padding: 0px 10px 0px;
  margin-top: 1em;
}

#logo-title {
  background: linear-gradient(315deg, #0cbaba 0%, #380036 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6em;
  padding: 15px;
  margin-left: 4px;
  font: normal normal bold Lato;
  letter-spacing: 0px;

  opacity: 1;
}

#crossButton {
  top: 5%;
  left: 5%;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1em;
  height: 2em;
}

.divider-bar {
  height: 0px;
  width: 12rem;
  margin: 0px 0px 0px 30px;
  background: #515153 0% 0% no-repeat padding-box;
  border: 1px solid #b6b6b7;
  border-radius: 5px;
  opacity: 1;
}

.checkbox-renderer {
  width: 16px;
  height: 16px;
  color: #515153 !important;
}

/* customview */

.no-custom-view {
  left: 50%;
  bottom: 55%;
  transform: translate(-50%, -50%);
  position: absolute;
  
  width:50%;
  height:20%;
  border:2px solid #1D2939;
  border-radius: 8px;
  display: flex;
  justify-content:center;
  align-items: center;
}
.no-custom-view span{
  color:#E1574D;
  
  font-size: 2rem;
  font-family: 'inter';
}
