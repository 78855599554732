:root{
  --table-button-height:50%
}

.Btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width:auto;
  white-space: nowrap;
  min-width: 6rem;
  height:4.2vh;
  min-height: 2.2rem;

  color: #344054;

  border-radius: 5px;
  border: 1px solid #b6b6b7;

  opacity: 1;

  font-family: "inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.5rem;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

@media only screen and (max-width: 1550px) {
  .Btn {
    font-size: 0.8rem;
  }
}

.createBtn,.btn-modalnav,.airflowBtn{
  background: #0042D2 0% 0% no-repeat padding-box;
  border: 1px solid #0042D2;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #fff;

  font-size: 1rem;
  width: 6rem;
  min-width: 5rem;
  padding-left: 7px;
  padding-right: 13px;
  height:var(--table-button-height);
  max-height: 3rem;
}

.btn-modalnav{
  width:3rem;
  min-width:2rem;
  height:2.5rem;
  padding:5px

}
.airflowBtn{
  background: #F28C28 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
}

.deleteBtn {
  box-sizing: border-box;
  border: 1.1px solid #b42318;
  color: #B42318;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius:8px;
  font-size: 1rem;
  font-weight: 600;
  width: fit-content;
  padding-inline: 10px;
  height:var(--table-button-height);
  max-height: 3rem;
}

.updateBtn,.applyBtn {
  border: 1px solid #ccdcff;
  width:fit-content ;
  font-size: 1rem;
  font-weight: 600rem;
  width: 5rem;
  width:fit-content;
  padding-inline: 15px;
  min-width: 5rem;
  border-radius: 8px;
  color: #0035a6;
  background: #ffffff;
  height:var(--table-button-height);
  max-height: 3rem;
}

.tableBtn,
.DownloadBtn {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.refreshBtn{
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height:var(--table-button-height);
  max-height: 3rem;
}

.DownloadBtn {
  width: fit-content;
  padding-inline: 10px;
  font-family: 'inter';
  font-size: .9rem;
  font-weight: 600;
  min-width: 8rem;
  height:var(--table-button-height);
  max-height: 3rem;
}

.applyBtn {
  font-family: 'inter';
  color:#344054;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

}



.db-back-btn {
  background: #fff;
  color: #333;
  width: auto;
  height: 30px;
  padding: 0;
}

.sendairflowBtn {
  background: orange 0% 0% no-repeat padding-box;
  margin-right: 5px;
}

.logoutBtn {
  background: #707070 0% 0% no-repeat padding-box;
  width: 110px;
  margin-left: 15px;
}

.btn-submit {
  width:100%;
  background: #0042d2;
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  height: 2.5rem;
  border: 1px solid #0042d2;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.btn-prevNext,.btn-prevNext-active{
  
  width:3rem ;
  height: 2.5rem;
  border: 1px solid #333;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  opacity:.2;
 
 
  
  
  border-radius: 8px;
}
.btn-prevNext-active{
  background-color: #e7eff9;
  background-image :linear-gradient(315deg, #e7eff9 0%, #cfd6e6 74%);
  opacity:1
}