.loader-view{

    padding-top:27px ;
}


.Loader__background{
    background-color:rgba(0,0,0,.6) !important;
   
}

.load-app-button{
    width: 150px;
    height: 45px;
    font-weight:700;
   
    font-size: 18px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
   
   
  
    
    
   
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    letter-spacing: 1.1px;
    
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    
    }
  
  .load-app-button:hover {
    background-color:#f0ad4e;
   
    color: #333;
    transform: translateY(-5px);
  }

/* .load-app-button{
    border:3px solid #f0ad4e	;
    border-radius:10px;
    padding:5px;
} */
.generic-loader{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
}