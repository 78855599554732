
.tableName::before,.tableName::after{
    --scale:0;
    --tooltip-color:#333;
    --arrow-size:8px;
    position:absolute;
    top:-.20rem;
    left:50%;
    transform: translateX(-50%) translateY(var(--translate-y,0)) scale(var(--scale));
    transition: 200ms transform;
    transform-origin: bottom;
 
}

.tableName::before{
    --translate-y:calc(-100% - var(--arrow-size));   
    content:attr(data-tooltip);
    color:white;
    background-color: var(--tooltip-color);

    padding:.5em;
    width:max-content;
    border-radius:5px;
    max-width: 100%;
    text-align:center;
    font-size:small;
    
  
   
    
}

.tableName:hover::before,.tableName:hover::after{
    --scale:1
}

.tableName::after{
    --translate-y:-12px;
    transform-origin:top;
    content:'';
    border:12px solid transparent;
    border-top-color:var(--tooltip-color);
 
}