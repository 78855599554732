.overlay-login {
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    position: absolute;
    background-color:#ffffff;
  
  }

.loginform-container{

    top:37%;
    left:50%;
    transform: translate(-50%,-50%);
    background: #fff;
    position:absolute;
    width:60%;
    max-width: 25rem;
    min-width:20rem;
    height:fit-content;
    border-radius: 5px;
    
    
}
.login-logo{
    
    width:100%;
    height:5rem;
    margin:1rem auto 1.6rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid #F2F4F7
}

.login-heading{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:5rem;
    margin-bottom: 1rem;
    
    
}
.login-title{
   
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #101828;
    margin-top: 1rem;
    margin-bottom:.5rem;
    letter-spacing: 1px;
   
   
   
}

.login-error{
    color:#FFF;
    padding:.75em .5em;
    margin:0;
    margin-top:1.5em;
    border-radius:8px;
    background: #F04438 ;

    
}
.login-subtext {
    font-family: 'inter';
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #475467;
    margin:.3rem 0 1.5rem 0;
   
   
}



.loginForm{
    color:#333;
    width:100%;
    height:fit-content;
    padding:5px;
}

.password-container{
   
    position:relative;
    height: 3rem;
   
    
}

.password-input{
    position: absolute;
    padding-right: 35px !important;
   

}
.password-container img {
    position: absolute;
    right:3%;
    top:50%;
    padding:1px;
    cursor: pointer;
    
}

.login-input{
    font-family: 'inter';
    width:100%;
    outline: none;
    height:2.8rem;
    border:1px solid #D0D5DD;
    border-radius:8px;
    margin:.5rem 0 1.2rem 0;
    padding:10px;
    padding-inline: 10px;
    
}

.login-fields{
    font-family: "inter";
    font-weight:550;
    font-style: normal;
    font-size:14px;
    line-height: 20px;
    color:#344054;
    
}




input[id="remember"]{
    margin-left:-.3rem;
    margin-right: .4rem;
    width:1rem;
    height:1rem;
    background: #FFFFFF;
    border:1px solid #D0D5DD !important;
    border-radius: 5px;
    cursor: pointer;

}
.forgot{
    color:#0035A6;
    text-decoration: none;
    font-family: 'inter';
    font-size: .8rem;
    font-weight: 600;
    line-height: 20px;
}

.submit-login{
    font-family: 'inter';
    font-weight: 600;
    font-size: 1.1rem;
    color:#FFFFFF;
    line-height: 1.5rem;
    width:100%;
    height:2.6rem;
    background: #0042D2;
    color:#FFFFFF;
    border-radius: 8px;
    border:1px solid #0042D2;
    
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow:0px 1px 2px rgba(16, 24, 40, 0.05);
    margin-top:1.25rem;
}