.pagination-container{
   
    width:var(--width);
    height:fit-content;
    
    position:absolute;
    
    width:95.6%;
    left:2.1%;
    
    bottom:4.3%;
  
   
   
}

.pagination-box {
    width:100%;
   
   
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'inter';
    font-style: normal;
    font-weight: 500;
    font-size: .9rem;
    line-height: 1.25 rem;
    color: #344054;

}

.pagination {
    display: flex;
    justify-content: flex-start;
    margin: 0 !important;

}

.page-link {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding:10px !important;;

    width: 2.3rem;
    height: 2rem;

    background: #FFFFFF;
    color: #344054 !important;

    border-right: 1px solid #D0D5DD;
    font-family: 'inter';
    font-style: normal;
    font-weight: 600;
    font-size: .8rem;
 
    line-height: 2.25rem;
    

}

.page-link:hover {
  
    border-radius: 2px !important;
 

}

.page-link:focus {
    background: #2D81A1 !important;
    border-radius: 2px !important;
    color: #fff !important;
    border: none !important;
    outline: none !important;
}



/* page input field styling */

.page-jump-to,#pagination-info {
    
    font-family: 'inter';
    font-style: normal;
    font-weight:500;
    font-size: 1rem;
    color: #344054;
  

}

.page-jump-to>input {
    width:4rem;
    height:2rem;
    border: 1px solid #B6B6B7;
    border-radius: 5px;
    opacity: 1;
    padding-left:1.25rem;
    
}

.page-jump-to>input:focus {
    outline: none;
}